window.scrollInfinity = {
	paginationNumeric: 1,
	buscarequest: false,
	feedbackElement: false,
	renderImoveisElement: false,
	imoveisRenderArrow: '<div class="down arrow"></div>',
	imoveisVisiveisMerged: {},
	urlToArray: url => {
		var request = {};
		var pairs = url.substring(url.indexOf('?') + 1).split('&');
		for (var i = 0; i < pairs.length; i++) {
			if(!pairs[i])
				continue;
			var pair = pairs[i].split('=');
			request[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
		}
		return request;
	},
	reset: f => {
		scrollInfinity.feedbackElement.innerHTML = scrollInfinity.imoveisRenderArrow;
		scrollInfinity.paginationNumeric = 1;
		scrollInfinity.buscarequest = false;
	},
	request: btn => {
		var conteudo_btn = '';
		if(btn){
			conteudo_btn = btn.innerHTML;
		}
		if(scrollInfinity.feedbackElement){
			scrollInfinity.feedbackElement.innerHTML = '<p class="text-center"><i class="icl font50 ic-spinner-third rotating"></i></p>';
		}
		scrollInfinity.paginationNumeric += 1;

		var tempFields = window.location.href+payloadBusca.payload;
		
		tempFields = scrollInfinity.urlToArray(tempFields);
		tempFields['pag'] = scrollInfinity.paginationNumeric;

		Boss.ajax({
			'url': '/imoveis/scroll',
			'data': tempFields,
			'dataType': 'json',
			'done': function(rtn){
				
				if(scrollInfinity.renderImoveisElement){
					scrollInfinity.renderImoveisElement.innerHTML = scrollInfinity.renderImoveisElement.innerHTML + rtn.data;
				}

				if(scrollInfinity.feedbackElement){
					scrollInfinity.feedbackElement.innerHTML = conteudo_btn;
				}

				if(typeof(loadingLazy.push) !== 'undefined'){
                	loadingLazy.push(document.getElementById('content'));
				}

				/* Isso é para pausar a busca por scroll */
				if(rtn['data'].length <= 50){
					scrollInfinity.buscarequest = true;
					if(scrollInfinity.feedbackElement){
						scrollInfinity.feedbackElement.innerHTML = '';
					}
				}
			}
		});
	},
	init: (footerElement, renderImoveisElement, feedbackElement) => {

		if(typeof(footerElement) == 'undefined'){ 
			console.warn('Ops, você precisa informar qual é o footer para o ScrollInfinity funcionar.');
			return false;
		}
		if(typeof(renderImoveisElement) == 'undefined'){
			console.warn('Ops, você precisa informar qual é o elemento que vai receber os imóveis para o ScrollInfinity funcionar.');
			return false;
		}

		scrollInfinity.renderImoveisElement = renderImoveisElement;
		scrollInfinity.feedbackElement = feedbackElement;
		
		document.addEventListener('scroll', f => {
			var tamanhoScreen = window.scrollY + footerElement.offsetHeight + 100;
			
			if(scrollInfinity.buscarequest){
				return false;
			}
			
			Boss.delayPersistent2(f => {
                
                var posicaoSeta = scrollInfinity.feedbackElement.offsetTop;
                if(width < 1024){
                    posicaoSeta -= 500;
                }else{
                    posicaoSeta -= 800;
                }

				if(tamanhoScreen <= posicaoSeta){
					return false;
				}

				scrollInfinity.request();

			}, 200, 'scrollInfinity');
		}, false);
	}
};