var animarspanduk = {};
window.spanduk = {
	spandukMemory: {},
	sizes: function(spanduk_id){

		if(Boss.getById(spanduk_id)){
			
			spanduk.slider(spanduk, false);

			var sizes = Boss.screensizes();

			var bann = Boss.getById(spanduk_id);
			var canvs = spanduk.spandukMemory[spanduk_id].canvs;

			canvs.classList.add('spanduk-canvas-hidden');

			bann.style.width = '';
			bann.style.height = '';
			bann.style.maxWidth = '';
			bann.style.maxHeight = '';

			canvs.style.width = '';
			canvs.style.height = '';
			canvs.style.maxWidth = '';
			canvs.style.maxHeight = '';

			Boss.delay(function(){

				var tProporcoes = spanduk.spandukMemory.proporcoes.length;
				var proporcoes = spanduk.spandukMemory.proporcoes;

				var imgs = 0;
				/* 1920 x 550 */
				var padraoProporcao = 3.84;
				var padraoProporcao1280 = 2.56;
				var largura = 0;
				var altura = 0;

				// ENCONTRA MENOR ALTURA
				for(x = 0; x < tProporcoes; x++){
					if(proporcoes[x].largura > 0 && proporcoes[x].altura > 0){
						imgs = imgs + 1;
						if(altura == 0){
							altura = proporcoes[x].altura;
							largura = proporcoes[x].largura;
						}
						if(proporcoes[x].altura < altura){
							altura = proporcoes[x].altura;
							largura = proporcoes[x].largura;
						}
					}
				}

				// PROCESSA PROPORÇÃO
				var lar = bann.clientWidth;
				if(altura > 0){
					padraoProporcao = (largura / altura).toFixed(4);
					padraoProporcao = (largura / altura).toFixed(4);
				}

				spanduk.spandukMemory[spanduk_id]['padraoProporcao'] = padraoProporcao;
				spanduk.spandukMemory[spanduk_id]['padraoProporcao1280'] = padraoProporcao1280;


				var alt = 0;
				var diff = 0
				if(lar < 1600){
					alt = (lar / padraoProporcao1280).toFixed(0);
					diff = lar;
					lar = (alt * padraoProporcao).toFixed(0);
					diff = lar - diff;
					diff = (diff / 2).toFixed(0);
					canvs.style.marginLeft = '-'+diff+'px';
				}else{
					alt = (lar / padraoProporcao).toFixed(0);
					canvs.style.marginLeft = '';
				}

				bann.style.width = lar+'px';
				bann.style.height = alt+'px';
				bann.style.maxWidth = lar+'px';
				bann.style.maxHeight = alt+'px';

				console.log(alt, lar);

				canvs.style.width = lar+'px';
				canvs.style.height = alt+'px';
				canvs.style.maxWidth = lar+'px';
				canvs.style.maxHeight = alt+'px';

				canvs.classList.remove('spanduk-canvas-hidden');
				Boss.delay(function(){
					spanduk.slider(spanduk, true);
				}, 6000);

			}, 300);
		}
	},
	before: function(spanduk_id){

		if(Boss.getById(spanduk_id)){

			var bn = Boss.getById(spanduk_id);
			var lar = bn.clientWidth;

			var atual = spanduk.spandukMemory[spanduk_id].atual;
			var total = spanduk.spandukMemory[spanduk_id].total;
			var divs = spanduk.spandukMemory[spanduk_id].divs;

			var legenda = spanduk.spandukMemory[spanduk_id].legenda;

			if((atual - 1) < 0){
				atual = (total - 1);
			}else{
				atual = atual - 1;
			}

			spanduk.spandukMemory[spanduk_id].atual = atual;

			var padraoProporcao = spanduk.spandukMemory[spanduk_id].padraoProporcao;

			var padraoProporcao1280 = spanduk.spandukMemory[spanduk_id].padraoProporcao1280;

			var diff = 0;
			if(lar < 1600){
				alt = (lar / padraoProporcao1280).toFixed(0);
				diff = lar;
				lar = (alt * padraoProporcao).toFixed(0);
				diff = lar- diff;
				diff = (diff / 2).toFixed(0);
			}

			for(x = 0; x < total; x++){
				
				divs[x].classList.remove('hidden');

				if(atual === x){

					var y = 0;

					if(typeof(divs[(x + 1)]) !== 'undefined'){
						y = x + 1;
					}

					divs[y].classList.remove('hidden');
					divs[y].style.zIndex = '3';
					/*divs[y].style.webkitTransform = "translateX(0px)";*/
					divs[y].style.transform = "translateX(0px)";
					divs[y].classList.add('sair-esquerda');


					/*divs[x].classList.remove('hidden');*/
					divs[x].classList.add('vir-da-direita');

					/*divs[x].style.webkitTransform = "translateX("+lar+"px)";*/
					divs[x].style.transform = "translateX("+(lar - diff)+"px)";
					divs[x].style.zIndex = 4;
					divs[x].style.opacity = 1;
					divs[x].classList.remove('hidden');
					divs[x].classList.remove('sair-esquerda');
					divs[x].classList.remove('sair-direita');
					divs[x].classList.add('vir-da-direita');

					var im = divs[x].getElementsByTagName('img');
					if(im[0].getAttribute('alt')){
						legenda.textContent = im[0].getAttribute('alt');
					}

				}else{
					divs[x].classList.remove('vir-da-direita');
					divs[x].classList.remove('vir-da-esquerda');
					divs[x].style.zIndex = '3';
				}
			}
		}
	},
	after: function(spanduk_id){

		if(Boss.getById(spanduk_id)){

			var bn = Boss.getById(spanduk_id);
			var lar = bn.clientWidth;

			var atual = spanduk.spandukMemory[spanduk_id].atual;
			var total = spanduk.spandukMemory[spanduk_id].total;
			var divs = spanduk.spandukMemory[spanduk_id].divs;

			var legenda = spanduk.spandukMemory[spanduk_id].legenda;

			if((atual + 1) < total){
				atual = atual + 1;
			}else{
				atual = 0;
				if(spanduk.spandukMemory[spanduk_id].endSlider){
					spanduk.spandukMemory[spanduk_id].endSlider();
				}
			}

			spanduk.spandukMemory[spanduk_id].atual = atual;

			var padraoProporcao = spanduk.spandukMemory[spanduk_id].padraoProporcao;

			var padraoProporcao1280 = spanduk.spandukMemory[spanduk_id].padraoProporcao1280;

			var diff = 0
			if(lar < 1600){
				alt = (lar / padraoProporcao1280).toFixed(0);
				diff = lar;
				lar = (alt * padraoProporcao).toFixed(0);
				diff = lar - diff;
				diff = (diff / 2).toFixed(0);
			}

			for(x = 0; x < total; x++){

				if(atual === x){
					var y = 0;

					if(typeof(divs[(x - 1)]) !== 'undefined'){
						y = x - 1;
					}else{
						y = total - 1;
					}

					divs[y].classList.remove('hidden');
					divs[y].style.zIndex = '3';
					/*divs[y].style.webkitTransform = "translateX(0px)";*/
					divs[y].style.transform = "translateX(0px)";
					divs[y].classList.add('sair-direita');

					/*divs[x].style.webkitTransform = "translateX(-"+lar+"px)";*/
					divs[x].style.transform = "translateX(-"+(lar - diff)+"px)";
					divs[x].style.zIndex = 4;
					divs[x].style.opacity = 1;
					divs[x].classList.remove('hidden');
					divs[x].classList.remove('sair-esquerda');
					divs[x].classList.remove('sair-direita');
					divs[x].classList.remove('vir-da-esquerda');
					divs[x].classList.add('vir-da-esquerda');

					var im = divs[x].getElementsByTagName('img');
					if(im[0].getAttribute('alt')){
						legenda.textContent = im[0].getAttribute('alt');
					}
				}else{
					divs[x].classList.remove('vir-da-direita');
					divs[x].classList.remove('vir-da-esquerda');
					divs[x].style.zIndex = '3';
				}
			}
		}
	},
	slider: function(spanduk_id, action){

		if(Boss.getById(spanduk_id)){

			var bann = Boss.getById(spanduk_id);

			/* NOACTION */
			if(typeof(action) === 'undefined'){

				if(bann.getAttribute('data-play') === null){
					spanduk.slider(spanduk, true);
				}else{
					spanduk.slider(spanduk, false);
				}

			/* SLIDE SET PLAY */
			}else if(action === true){
				
				if(typeof(spanduk.spandukMemory[spanduk_id].button_slide) !== 'undefined'){
					button_slide.classList.add('spanduk-slide-pause');
				}

				bann.setAttribute('data-play', 'true');

				clearInterval(animarspanduk[spanduk_id]);
				animarspanduk[spanduk_id] = setInterval(function(){
					spanduk.after(spanduk_id);
				}, 6000);

			/* SLIDE SET PAUSE */
			}else if(action === false){

				if(typeof(spanduk.spandukMemory[spanduk_id].button_slide) !== 'undefined'){
					button_slide.classList.remove('spanduk-slide-pause');
				}

				bann.removeAttribute('data-play');

				clearInterval(animarspanduk[spanduk_id]);

			}
		}
	},
	init: function(spanduk_id, fns){

		if(Boss.getById(spanduk_id)){

			clearInterval(animarspanduk[spanduk_id]);

			var bann = Boss.getById(spanduk_id);

			var canvs = bann.querySelector('div[data-spanduk="canvas"]');

			var legenda = bann.querySelector('span[data-spanduk="nav-legenda"]');

			var nav_left = bann.querySelector('button[data-spanduk="nav-left"]');

			var nav_right = bann.querySelector('button[data-spanduk="nav-right"]');

			var button_slide = bann.querySelector('span[data-spanduk="button-slide"]');

			spanduk.spandukMemory[spanduk_id] = {};
			spanduk.spandukMemory[spanduk_id]['atual'] = 0;
			spanduk.spandukMemory[spanduk_id]['canvs'] = canvs;
			spanduk.spandukMemory[spanduk_id]['legenda'] = legenda;
			spanduk.spandukMemory[spanduk_id]['nav_left'] = nav_left;
			spanduk.spandukMemory[spanduk_id]['nav_right'] = nav_right;

			if(button_slide){

				spanduk.spandukMemory[spanduk_id]['button_slide'] = button_slide;

				/* PLAY PAUSE */
				Boss.evts.add('click', button_slide, function(evts){
					spanduk.slider(spanduk_id);
				});

			}

			if(typeof(fns) === 'object'){
				if(typeof(fns.endSlider) === 'function'){
					spanduk.spandukMemory[spanduk_id]['endSlider'] = fns.endSlider;
				}
			}

			/* DIVs */
			var divs = canvs.getElementsByTagName('div');
			var ldivs = divs.length;

			spanduk.spandukMemory[spanduk_id]['total'] = ldivs;
			spanduk.spandukMemory[spanduk_id]['divs'] = divs;

			if(ldivs < 2){
				nav_left.classList.add('hidden');
				nav_right.classList.add('hidden');
				legenda.classList.add('hidden');
			}

			canvs.classList.add('spanduk-canvas-hidden');

			/* SHOW FIRST IMAGE */
			for(i = 0; i < ldivs; i++){
				if(i > 0){
					divs[i].classList.add('hidden');
				}
				if(i < 1){
					var im = divs[i].getElementsByTagName('img');
					if(im[0] && im[0].getAttribute('alt')){
						legenda.textContent = im[0].getAttribute('alt');
					}
				}
			}

			var imgs = canvs.getElementsByTagName('img');
			var limgs = divs.length;

			var loadimg = 0;

			/* PRE-LOADER */
			spanduk.spandukMemory['proporcoes'] = Array();
			for(i = 0; i < limgs; i++){

				// MÉDIAS DAS PROPORÇÕES
				var im = new Image();
				Boss.evts.add('load', im, function(evts){

					loadimg = loadimg + 1;

					spanduk.spandukMemory.proporcoes.push({'largura': im.naturalWidth, 'altura': im.naturalHeight});

					if(loadimg === limgs){
						spanduk.sizes(spanduk_id);
					}
				});
				im.src = imgs[i].getAttribute('src');
			}


			var screensizes = Boss.screensizes();
			lastWidth = screensizes.viewWidth;

			/* RESIZE EVENT */
			Boss.evts.add('resize', window, function(evt){

				var screensizes = Boss.screensizes();
				if(lastWidth != screensizes.viewWidth){
					Boss.delayPersistent(function(){
						lastWidth = screensizes.viewWidth;
						spanduk.sizes(spanduk_id);
					}, 250);
				}
			});

			/* BEFORE IMAGE */
			Boss.evts.add('click', nav_left, function(evts){
				spanduk.before(spanduk_id);
				spanduk.slider(spanduk_id, false);

				Boss.delay(function(){
					spanduk.slider(spanduk_id, true);
				}, 20000);
			});

			/* AFTER IMAGE */
			Boss.evts.add('click', nav_right, function(evts){
				spanduk.after(spanduk_id);
				spanduk.slider(spanduk_id, false);

				Boss.delay(function(){
					spanduk.slider(spanduk_id, true);
				}, 20000);
			});

			/* EVENTOS DO TECLADO */
			Boss.evts.add('keyup', Boss.getById(spanduk_id), function(evts){

				var bann = Boss.getById(spanduk_id);

					/* LEFT OR SPACE */
				if(evts.keyCode === 37 || evts.keyCode === 32){

					spanduk.before(spanduk_id);
					spanduk.slider(spanduk, false);

					Boss.delay(function(){
						spanduk.slider(spanduk, true);
					}, 20000);

				/* RIGHT */
				}else if(evts.keyCode === 39){

					spanduk.after(spanduk_id);
					spanduk.slider(spanduk, false);

					Boss.delay(function(){
						spanduk.slider(spanduk, true);
					}, 20000);
				}
			});
		}else{
			console.warn('Element not valid!');
		}
	}
};