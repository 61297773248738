/* INICIO RATE */
window.Rate = {
	nota: 0,
	imovel: 0,
	init: el => {

		if(Boss.getById(el)){

			var elem = Boss.getById(el);

			Rate.nota = Number(elem.getAttribute('data-rate'));
			Rate.imovel = Number(elem.getAttribute('data-imovel'));

			/* APLICA PADRÃO INICIAL */
			Rate.render(el, Rate.nota);

			Boss.evts.add('mouseover', Boss.getById(el), function(evt){

				Boss.delayPersistent2(function(){
					var nt = Boss.targt(evt);
					Rate.render(el, nt.getAttribute('data-nota'));
				}, 16, 'rate_move');
			});

			Boss.evts.add('mouseout', Boss.getById(el), function(evt){
				Boss.delayPersistent2(function(){
					Rate.render(el, Rate.nota);
				}, 15, 'rate_out');
			});
		}
	},
	render: (el, nota) => {

		if(Boss.getById(el)){

			var elem = Boss.getById(el);

			rate_area_feed.textContent = nota;

			var btns = elem.getElementsByTagName('button');
			var bts = btns.length;

			for(x = 0; x < bts; x++){
				x < nota ? btns[x].firstChild.classList.add('ics') : btns[x].firstChild.classList.remove('ics');
			}
		}
	},
	save: (el, nota) => {

		if(Boss.getById(el) && nota > 0){

			var elem = Boss.getById(el);

			Rate.nota = nota;
			Rate.imovel = Number(elem.getAttribute('data-imovel'));

			elem.setAttribute('data-rate', Rate.nota);

			Boss.ajax({
				'url': '/favoritos/nota',
				'data': {
					'nota': Rate.nota,
					'imovel': Rate.imovel
				},
				done: function(rtn){
					Rate.render(el, Rate.nota);
				}
			});
		}

		Rate.render(el, Rate.nota);
	}
};
/* FIM RATE */